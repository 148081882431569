:root {
  --ck-resizer-tooltip-height: var(--space-xl);
}

.ck.ck-content blockquote {
  font-style: normal;
}

.ck.ck-content a[target='_blank']::after {
  content: ' \f35d';
  font: var(--kog-content-fa-icon);
}

.ck.ck-content .image > figcaption,
.ck.ck-content .table > figcaption,
.ck.ck-content .iframe-container > figcaption {
  font-size: var(--kog-font-size-content);
}

.ck.ck-content .iframe-container > figcaption {
  padding: 0.6em;
}

.ck.ck-content .image .ck-size-view {
  font-size: var(--ck-font-size-normal);
}

.ck.ck-toolbar.hidden-toolbar {
  display: none;
}

.ck-form:not(.ck-table-form) .ck-dropdown__panel {
  overflow-y: scroll;
}

.ck-form:not(.ck-table-form) .ck.ck-dropdown {
  width: 6em;
}

.ck.ck-form:not(.ck-table-form) {
  display: grid;
  grid-column-gap: 0;
  grid-row-gap: var(--ck-spacing-standard);
  grid-template-columns: repeat(1fr);
  grid-template-rows: repeat(1fr);

  width: 18em;
  padding: var(--ck-spacing-large);
}

.ck-widget.html-object-embed .html-object-embed__content {
  pointer-events: auto !important;
}
