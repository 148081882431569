.content-editable .CKEditorKognityGlossaryLink {
  display: inline-block;

  color: var(--kog-link-color);
  text-decoration-line: underline;
  text-decoration-style: dashed;
  text-decoration-thickness: 1px;
  text-underline-offset: 4px;
}

.content-editable .CKEditorKognityGlossaryLink p {
  margin: 0;
  padding: 0;
}
