.content-editable .exercise-box {
  position: relative;

  padding: var(--space-l) var(--space-l) var(--space-xxl);

  font-family: var(--kog-content-main);
  font-size: var(--kog-font-size-default);
  font-feature-settings: 'lnum';
  color: var(--kogPlatformGray018);

  background: var(--kog-content-purple-3);
  border-radius: 24px;
}

.content-editable .exercise-box .exercise-box-header {
  position: relative;
}

.content-editable .exercise-box .icon_div {
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  width: var(--space-xxl);
  height: var(--space-xxl);

  font-size: var(--kog-font-size-content);
  color: var(--kogPlatformGray018);

  background-color: var(--kogPlatformWhite);
  border-radius: 50%;
}

.content-editable .exercise-box .icon_div span {
  font: var(--kog-content-fa-icon);
  font-size: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.content-editable .exercise-box .icon_div span::before {
  content: '\f891'; /* Icon for .fa-shapes */
  font-size: 18px;
}

.content-editable .exercise-box .exercise-box-title {
  margin-left: 56px;
}

.content-editable .exercise-box .exercise-box-title :is(h2, h3, h4, h5, h6) {
  margin: 0;
  padding: 0;

  font-family: var(--kog-satoshi);
  font-size: var(--kog-font-size-navbar-icons);
  font-weight: bold;
  line-height: 40px;
  color: var(--kogPlatformGray018);
}

.exercise-box-subtitle {
  margin: var(--space-xs) 0 var(--space-l) var(--space-xxxl);

  font-family: var(--kog-satoshi);
  font-size: var(--kog-font-size-content);
  font-weight: 500;
  color: var(--kogPlatformGray018);
}
